import React  from "react";
import {Text,Heading,Box, SimpleGrid} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import Link from '../components/gatsbylink'
import {motion} from 'framer-motion'
import MySeo from '../components/seo' 

export default function Keller(){
    return(
        <motion.div 
        initial={{opacity:0,paddingBottom:"100px"}}
        animate={{
          opacity:1
        }}
        transition={{duration:.1,delay:.1,ease:"circInOut"}}
        >   
        <MySeo title="Das Schicksal der jüdischen Familie Keller aus Bacharach" />
        <Box  mt="60px"><Link to="/bacharach"><Text> &rarr; back</Text></Link></Box>
        <SimpleGrid mt="60px" gap={4} columns={[1,1,2]}>
        <Box>
            <Heading fontSize="lg" py="8">Wilhelm Keller (1894– ?) und Emma Keller (1893– ?)</Heading>
            
            <Text variant="solid">
            Wilhelm Keller wurde am 13. Mai 1894 in Bacharach geboren und hatte einen Bruder namens Josef sowie eine Schwester namens Emma. Er war der Sohn von Abraham Keller und Adelheid Keller, geb. Wolff. Der Vater starb 1930, sein Grabstein befindet sich noch immer auf dem jüdischen Friedhof in Bacharach. Wilhelm war gelernter Schneider und blieb zeitlebens ledig. Er wohnte im Haus seiner Eltern in der Langstraße 43, die dort eine Metzgerei betrieben. Er und seine Schwester Emma halfen den Eltern im Geschäft.
            </Text>
           
            <Text variant="solid">
            Im Zuge des Novemberpogroms 1938 kam es auch zu Ausschreitungen in Bacharach: Die Synagoge, heute Blücherstraße 28, wurde geplündert und Teile ihrer Ausstattung wurden am Rheinufer verbrannt. Nur die Thorarollen wurden von einem der Angreifer zur Seite geschafft und konnten später zurückgegeben werden. In den Tagen nach diesen gewalttätigen Übergriffen wurden viele Tausend jüdische Männer verhaftet und in Konzentrationslager gesperrt, wo man sie misshandelte, um den Druck zur Emigration zu verstärken. Auch Wilhelm Keller wurde von November 1938 bis März 1939 in Dachau interniert. Trotz des erpressten Zugeständnisses, bald auszuwandern, blieb er seiner Heimatstadt treu. Nach Aussage von Metzger Heinz Hauser hat dieser Wilhelm Keller in seinem Geschäft neben dem Marktturm bis 1941 oder 1942 beschäftigt, obwohl dies streng verboten war
            </Text>
            <Text variant="solid">
            Über den Verlauf seines weiteren Schicksals existieren widersprüchliche Angaben. Für den 26. Juli 1942 liegt in Bacharach eine Abmeldung von Wilhelm Keller vor, an diesem Tag soll er Informationen des Bundesarchivs zufolge nach Theresienstadt deportiert worden sein. Er steht jedoch nicht auf der Transportliste und ist auch nicht im Theresienstädter Gedenkbuch aufgeführt. Eigentlich war er für eine Deportation nach Theresienstadt „zu jung“, da im Sommer 1942 vorwiegend alte Menschen dorthin gebracht wurden. Ein Hinweis der Gestapo Koblenz erklärt hingegen, dass Wilhelm Keller beabsichtigte auszuwandern und am 30. April 1942 mit unbekanntem Ziel ins Ausland verzogen sei – in der NS-Verwaltungssprache eine zynische Umschreibung für die Deportation. Möglicherweise wurde er also zuvor mit einem Transport von Koblenz in das Ghetto Izbica im besetzten Polen gebracht. Sein Name erscheint jedoch auf keiner Deportationsliste, er galt als vermisst. Am 14. Mai 1956 wurde er auf Wunsch seiner Schwägerin Auguste Keller, Milwaukee, Wisconsin USA zum Ende des Jahres 1945 für tot erklärt.
            </Text>
            <Text variant="solid">
            Emma Keller wurde am 15. Februar 1893 geboren, sie blieb unverheiratet und wohnte bei ihren Eltern in der Langstraße 43. Dem Schicksal ihres Bruders entsprechend sind auch bei Emma Keller Ort und Zeitpunkt des Todes ungeklärt. Sie wurde ebenfalls am 26. Juli 1942 in Bacharach abgemeldet und Angaben des Bundesarchivs zufolge nach Theresienstadt deportiert. Doch auch ihr Name befindet sich weder auf einer Transportliste noch im Theresienstädter Gedenkbuch. Ihre Schwägerin Auguste Keller ließ sie zum 31. Dezember 1945 für tot erklären. Für Emma und Wilhelm Keller wurde 2014 ein Stolperstein in der Langstraße 43 verlegt.
            </Text>
            
           
            </Box>
            <Box  my="9">
               <Box pt={["1","1","16"]}>
                    <StaticImage src="../images/bacharach/16.jpg" alt="Gustav und Henriette Gerson &amp; Else Trum"/>
                    <Text variant="Bild">Willy Keller &amp;Emma Keller </Text>
                </Box>
               
                
            </Box>
            </SimpleGrid>
            </motion.div>
            
    )
}